<template>
	<div>
		<div class="activityList" v-if="windowWidth>=1200">
			<div class="al_dataList" v-for="(item,index) in $t('factoryDisplay.activity.list')" :key="index">
				<div class="al_dl_left">
					<div class="al_dl_le_topDiv">
						<img class="al_dl_le_td_img":src="item.img" />
						<div class="al_dl_le_td_content">
							<div class="al_dl_le_td_ct_title">
								{{item.title}}
							</div>
							<div class="al_dl_le_td_ct_time">{{item.time}}</div>
						</div>
					</div>
					<div class="al_dl_le_text">
						{{item.text}}
					</div>
				</div>
				
				<div class="al_dl_right">
					<div class="al_dl_ri_div">
						<div class="al_dl_ri_di_dataList" v-for="(item2,index2) in item.imgList" :key="index2">
							<img class="al_dl_ri_di_dl_img" :src="item2.img" />
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="activityList2" v-if="windowWidth<=1199">
			<div class="al_dataList" v-for="(item,index) in $t('factoryDisplay.activity.list')" :key="index">
				<div class="al_dl_left">
					<div class="al_dl_le_topDiv">
						<img class="al_dl_le_td_img":src="item.img" />
						<div class="al_dl_le_td_content">
							<div class="al_dl_le_td_ct_title">
								{{item.title}}
							</div>
							<div class="al_dl_le_td_ct_time">{{item.time}}</div>
						</div>
					</div>
					<div class="al_dl_le_text">
						{{item.text}}
					</div>
				</div>
				
				<div class="al_dl_right">
					<div class="al_dl_ri_div">
						<div class="al_dl_ri_di_dataList" v-for="(item2,index2) in item.imgList" :key="index2">
							<img class="al_dl_ri_di_dl_img" :src="item2.img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.activityList{
			padding: 40px 200px;
			
			.al_dataList{
				padding: 20px;
				background: #fff;
				width: 100%;
				display: flex;
				margin-bottom: 20px;
				
				.al_dl_left{
					width: 20%;
					border-right: 1px solid #e5e5e5;
					padding-right: 20px;
					
					.al_dl_le_topDiv{
						padding: 20px 0;
						display: flex;
						border-bottom: 1px solid #e5e5e5;
						
						.al_dl_le_td_img{
							width: 100px;
							height: 80px;
						}
						
						.al_dl_le_td_content{
							padding-left: 10px;
							
							.al_dl_le_td_ct_title{
								width: 100%;
								font-size: 16px;
								font-weight: bold;
							}
							
							.al_dl_le_td_ct_time{
								font-size: 12px;
								color: #999;
								padding-top: 10px;
							}
						}
					}
					
					.al_dl_le_text{
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						color: #999;
						font-size: 12px;
						padding-top: 20px;
					}
				}
				
				.al_dl_right{
					width: 80%;
					
					.al_dl_ri_div{
						width: 100%;
						padding: 20px;
						display: flex;
						
						.al_dl_ri_di_dataList{
							width: 24%;
							margin-right: 1%;
							
							.al_dl_ri_di_dl_img{
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.activityList{
			padding: 40px 200px;
			
			.al_dataList{
				padding: 20px;
				background: #fff;
				width: 100%;
				display: flex;
				margin-bottom: 20px;
				
				.al_dl_left{
					width: 20%;
					border-right: 1px solid #e5e5e5;
					padding-right: 20px;
					
					.al_dl_le_topDiv{
						padding: 20px 0;
						display: flex;
						border-bottom: 1px solid #e5e5e5;
						
						.al_dl_le_td_img{
							width: 100px;
							height: 80px;
						}
						
						.al_dl_le_td_content{
							padding-left: 10px;
							
							.al_dl_le_td_ct_title{
								width: 100%;
								font-size: 16px;
								font-weight: bold;
							}
							
							.al_dl_le_td_ct_time{
								font-size: 12px;
								color: #999;
								padding-top: 10px;
							}
						}
					}
					
					.al_dl_le_text{
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						color: #999;
						font-size: 12px;
						padding-top: 20px;
					}
				}
				
				.al_dl_right{
					width: 80%;
					
					.al_dl_ri_div{
						width: 100%;
						padding: 20px;
						display: flex;
						
						.al_dl_ri_di_dataList{
							width: 24%;
							margin-right: 1%;
							
							.al_dl_ri_di_dl_img{
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.activityList{
			padding: 40px 140px;
			
			.al_dataList{
				padding: 20px;
				background: #fff;
				width: 100%;
				display: flex;
				margin-bottom: 20px;
				
				.al_dl_left{
					width: 20%;
					border-right: 1px solid #e5e5e5;
					padding-right: 20px;
					
					.al_dl_le_topDiv{
						padding: 20px 0;
						display: flex;
						border-bottom: 1px solid #e5e5e5;
						
						.al_dl_le_td_img{
							width: 100px;
							height: 80px;
						}
						
						.al_dl_le_td_content{
							padding-left: 10px;
							
							.al_dl_le_td_ct_title{
								width: 100%;
								font-size: 16px;
								font-weight: bold;
							}
							
							.al_dl_le_td_ct_time{
								font-size: 12px;
								color: #999;
								padding-top: 10px;
							}
						}
					}
					
					.al_dl_le_text{
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						color: #999;
						font-size: 12px;
						padding-top: 20px;
					}
				}
				
				.al_dl_right{
					width: 80%;
					
					.al_dl_ri_div{
						width: 100%;
						padding: 20px;
						display: flex;
						
						.al_dl_ri_di_dataList{
							width: 24%;
							margin-right: 1%;
							
							.al_dl_ri_di_dl_img{
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.activityList{
			padding: 40px 30px;
			
			.al_dataList{
				padding: 20px;
				background: #fff;
				width: 100%;
				display: flex;
				margin-bottom: 20px;
				
				.al_dl_left{
					width: 20%;
					border-right: 1px solid #e5e5e5;
					padding-right: 20px;
					
					.al_dl_le_topDiv{
						padding: 20px 0;
						display: flex;
						border-bottom: 1px solid #e5e5e5;
						
						.al_dl_le_td_img{
							width: 100px;
							height: 80px;
						}
						
						.al_dl_le_td_content{
							padding-left: 10px;
							
							.al_dl_le_td_ct_title{
								width: 100%;
								font-size: 16px;
								font-weight: bold;
							}
							
							.al_dl_le_td_ct_time{
								font-size: 12px;
								color: #999;
								padding-top: 10px;
							}
						}
					}
					
					.al_dl_le_text{
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						color: #999;
						font-size: 12px;
						padding-top: 20px;
					}
				}
				
				.al_dl_right{
					width: 80%;
					
					.al_dl_ri_div{
						width: 100%;
						padding: 20px;
						display: flex;
						
						.al_dl_ri_di_dataList{
							width: 24%;
							margin-right: 1%;
							
							.al_dl_ri_di_dl_img{
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.activityList2{
			padding: 40px 30px;
			
			.al_dataList{
				padding: 20px;
				background: #fff;
				width: 100%;
				margin-bottom: 20px;
				
				.al_dl_left{
					width: 100%;
					padding-right: 20px;
					
					.al_dl_le_topDiv{
						padding: 20px 0;
						display: flex;
						border-bottom: 1px solid #e5e5e5;
						
						.al_dl_le_td_img{
							width: 100px;
							height: 80px;
						}
						
						.al_dl_le_td_content{
							padding-left: 10px;
							
							.al_dl_le_td_ct_title{
								width: 100%;
								font-size: 16px;
								font-weight: bold;
							}
							
							.al_dl_le_td_ct_time{
								font-size: 12px;
								color: #999;
								padding-top: 10px;
							}
						}
					}
					
					.al_dl_le_text{
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						color: #999;
						font-size: 12px;
						padding-top: 20px;
					}
				}
				
				.al_dl_right{
					width: 100%;
					
					.al_dl_ri_div{
						width: 100%;
						padding: 20px;
						display: flex;
						
						.al_dl_ri_di_dataList{
							width: 24%;
							margin-right: 1%;
							
							.al_dl_ri_di_dl_img{
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.activityList2{
			padding: 40px 30px;
			
			.al_dataList{
				padding: 20px;
				background: #fff;
				width: 100%;
				margin-bottom: 20px;
				
				.al_dl_left{
					width: 100%;
					padding-right: 20px;
					
					.al_dl_le_topDiv{
						padding: 20px 0;
						display: flex;
						border-bottom: 1px solid #e5e5e5;
						
						.al_dl_le_td_img{
							width: 100px;
							height: 80px;
						}
						
						.al_dl_le_td_content{
							padding-left: 10px;
							
							.al_dl_le_td_ct_title{
								width: 100%;
								font-size: 16px;
								font-weight: bold;
							}
							
							.al_dl_le_td_ct_time{
								font-size: 12px;
								color: #999;
								padding-top: 10px;
							}
						}
					}
					
					.al_dl_le_text{
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						color: #999;
						font-size: 12px;
						padding-top: 20px;
					}
				}
				
				.al_dl_right{
					width: 100%;
					
					.al_dl_ri_div{
						width: 100%;
						padding: 20px;
						display: flex;
						flex-wrap: wrap;
						
						.al_dl_ri_di_dataList{
							width: 48%;
							margin-right: 1%;
							margin-bottom: 1%;
							
							.al_dl_ri_di_dl_img{
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	
</style>